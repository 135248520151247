var render = function () {
  var _vm$caseData, _vm$caseData2, _vm$filesToUpload, _vm$filesToUpload2, _vm$caseData$comments, _vm$caseData$comments2;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    ref: "caseModal",
    staticClass: "case-modal"
  }, [_c('div', {
    staticClass: "case-modal__bg",
    on: {
      "click": function click($event) {
        return _vm.$emit('close');
      }
    }
  }), _c('div', {
    staticClass: "case-modal__box"
  }, [_c('div', {
    class: ['case-modal__loader', _vm.isLoading ? 'active' : '']
  }, [_c('div', {
    staticClass: "spinner"
  }, [_c('b-spinner')], 1)]), _c('div', {
    staticClass: "case-modal__actions"
  }, [_c('button', {
    staticClass: "case-modal__action case-modal__action--close",
    on: {
      "click": function click($event) {
        return _vm.$emit('close');
      }
    }
  }, [_vm._v("Close")])]), _c('div', {
    staticClass: "case-modal__inner"
  }, [_c('div', {
    staticClass: "case-modal__body"
  }, [_c('p', {
    staticClass: "case-modal__subtitle"
  }, [_vm._v("TITLE")]), _c('h2', {
    staticClass: "case-modal__header"
  }, [_vm._v(_vm._s(_vm.caseData.title))]), _c('div', {
    staticClass: "case-details"
  }, [_c('div', {
    staticClass: "case-details__single"
  }, [_c('div', {
    staticClass: "case-details__label"
  }, [_vm._v("STATUS")]), _c('div', {
    staticClass: "case-details__value"
  }, [_c('div', {
    staticClass: "case-status"
  }, [_c('sygni-rounded-button', {
    class: ['outline round', _vm.getCaseStatusClassName(_vm.caseData.status)],
    attrs: {
      "hoverable": false
    }
  }, [_vm._v(_vm._s(_vm._f("capitalizeFirstLetter")(_vm.caseData.status)))]), ((_vm$caseData = _vm.caseData) === null || _vm$caseData === void 0 ? void 0 : _vm$caseData.status) != 'closed' && ((_vm$caseData2 = _vm.caseData) === null || _vm$caseData2 === void 0 ? void 0 : _vm$caseData2.status) != 'spam' ? _c('sygni-link-button', {
    attrs: {
      "type": "simple"
    },
    on: {
      "click": _vm.openStatusChangeModal
    }
  }, [_vm._v("Change")]) : _vm._e()], 1)])]), _c('div', {
    staticClass: "case-details__single"
  }, [_c('div', {
    staticClass: "case-details__label"
  }, [_vm._v("CASE TOKEN")]), _c('div', {
    staticClass: "case-details__value"
  }, [_vm._v(_vm._s(_vm.caseData.caseToken))])]), _c('div', {
    staticClass: "case-details__single"
  }, [_c('div', {
    staticClass: "case-details__label"
  }, [_vm._v("CATEGORY")]), _c('div', {
    staticClass: "case-details__value"
  }, [_vm._v(_vm._s(_vm.caseData.category))])]), _vm.caseData.createdAt ? _c('div', {
    staticClass: "case-details__single"
  }, [_c('div', {
    staticClass: "case-details__label"
  }, [_vm._v("CREATED")]), _c('div', {
    staticClass: "case-details__value"
  }, [_vm._v(_vm._s(_vm._f("dateWithoutTime")(_vm.caseData.createdAt)) + " | " + _vm._s(_vm._f("dateGetTime")(_vm.caseData.createdAt)))])]) : _vm._e(), _c('div', {
    staticClass: "case-details__single"
  }, [_c('div', {
    staticClass: "case-details__label"
  }, [_vm._v("NAME")]), _c('div', {
    staticClass: "case-details__value"
  }, [_vm._v(_vm._s(_vm.caseData.name ? "".concat(_vm.caseData.name, " ").concat(_vm.caseData.surname ? _vm.caseData.surname : '') : 'N/A'))])]), _c('div', {
    staticClass: "case-details__single"
  }, [_c('div', {
    staticClass: "case-details__label"
  }, [_vm._v("PHONE")]), _c('div', {
    staticClass: "case-details__value"
  }, [_vm._v(_vm._s(_vm.caseData.phone ? _vm.caseData.phone : 'N/A'))])]), _c('div', {
    staticClass: "case-details__single"
  }, [_c('div', {
    staticClass: "case-details__label"
  }, [_vm._v("EMAIL")]), _c('div', {
    staticClass: "case-details__value"
  }, [_vm._v(_vm._s(_vm.caseData.email ? _vm.caseData.email : 'N/A'))])]), _c('div', {
    staticClass: "case-details__single case-details__single--block"
  }, [_c('div', {
    staticClass: "case-details__label"
  }, [_vm._v("FILES")]), _c('div', {
    staticClass: "case-details__value"
  }, [_vm.caseData.documents.length || (_vm$filesToUpload = _vm.filesToUpload) !== null && _vm$filesToUpload !== void 0 && _vm$filesToUpload.length ? _c('div', {
    staticClass: "file-boxes"
  }, [_vm._l(_vm.caseData.documents, function (file) {
    return _c('sygni-file-box', {
      key: file.id,
      attrs: {
        "icon": "DOWNLOAD"
      },
      on: {
        "click": function click($event) {
          return _vm.downloadFile(file);
        }
      }
    }, [_vm._v(_vm._s(file.fileName))]);
  }), _vm._l(_vm.filesToUpload, function (file) {
    return _c('sygni-file-box', {
      key: file.id,
      attrs: {
        "icon": "DELETE"
      },
      on: {
        "click": function click($event) {
          return _vm.deleteFile(file);
        }
      }
    }, [_vm._v(_vm._s(file.fileName))]);
  })], 2) : _vm._e(), _vm.isEditable ? _c('div', {
    staticClass: "case-details__btns"
  }, [_c('sygni-rounded-button', {
    staticClass: "filled black",
    attrs: {
      "loading": _vm.areFilesLoading
    },
    on: {
      "click": _vm.toggleFileUpload
    }
  }, [_vm._v("Upload new file")]), _c('sygni-rounded-button', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: (_vm$filesToUpload2 = _vm.filesToUpload) === null || _vm$filesToUpload2 === void 0 ? void 0 : _vm$filesToUpload2.length,
      expression: "filesToUpload?.length"
    }],
    staticClass: "filled red",
    on: {
      "click": _vm.saveFiles
    }
  }, [_vm._v("Save files")])], 1) : _vm._e(), _c('input', {
    ref: "fileInput",
    attrs: {
      "type": "file",
      "multiple": "",
      "hidden": ""
    },
    on: {
      "input": _vm.uploadFiles
    }
  })])]), _c('div', {
    staticClass: "case-details__divider"
  }), _c('div', {
    staticClass: "case-details__single case-details__single--block"
  }, [_c('div', {
    staticClass: "case-details__label"
  }, [_vm._v("DESCRIPTION")]), _c('div', {
    staticClass: "case-details__value"
  }, [_c('div', {
    staticClass: "case-details__content"
  }, [_vm._v(" " + _vm._s(_vm.caseData.description) + " ")])])])])]), _c('div', {
    class: ['case-comments', ((_vm$caseData$comments = _vm.caseData.comments) === null || _vm$caseData$comments === void 0 ? void 0 : _vm$caseData$comments.length) == 0 ? 'case-comments--empty' : '']
  }, [_c('sygni-loader', {
    attrs: {
      "isLoading": _vm.areCommentsLoading
    }
  }), (_vm$caseData$comments2 = _vm.caseData.comments) !== null && _vm$caseData$comments2 !== void 0 && _vm$caseData$comments2.length ? _c('div', {
    staticClass: "case-comments__container"
  }, _vm._l(_vm.caseData.comments, function (comment, index) {
    return _c('div', {
      key: "comment-".concat(index),
      class: ['case-comments__single', !comment.beenRead ? 'case-comments__single--unread' : '']
    }, [_c('div', {
      staticClass: "case-comments__header"
    }, [_c('p', {
      staticClass: "case-comments__author"
    }, [_vm._v(_vm._s(comment.author.toUpperCase()) + " WROTE MESSAGE")]), _c('p', {
      staticClass: "case-comments__date"
    }, [_vm._v(_vm._s(_vm._f("dateWithoutTime")(comment.createdAt)))]), _c('p', {
      staticClass: "case-comments__time"
    }, [_vm._v(_vm._s(_vm._f("dateGetTime")(comment.createdAt)))])]), _c('div', {
      staticClass: "case-comments__content"
    }, [_vm._v(" " + _vm._s(comment.content) + " ")])]);
  }), 0) : _vm._e(), _vm.unreadComments.length ? _c('div', {
    staticClass: "case-comments__mark-as-read"
  }, [_c('sygni-link-button', {
    staticClass: "red",
    attrs: {
      "type": "simple"
    },
    on: {
      "click": _vm.markCommentsAsRead
    }
  }, [_vm._v("MARK ALL COMMENTS AS READ")])], 1) : _vm._e(), _vm.isEditable ? _c('sygni-input-group', {
    attrs: {
      "placeholder": "Type here to write message",
      "btnText": "Send message",
      "btnClasses": "filled black"
    },
    on: {
      "click": _vm.createComment
    },
    model: {
      value: _vm.commentMessage,
      callback: function callback($$v) {
        _vm.commentMessage = $$v;
      },
      expression: "commentMessage"
    }
  }) : _vm._e()], 1)]), _vm.showStatusChangeModal ? _c('sygni-modal', {
    attrs: {
      "isLoading": _vm.isStatusLoading,
      "header": "Select status",
      "cancelText": "Cancel",
      "confirmText": "Confirm change",
      "overflow": false
    },
    on: {
      "cancel": _vm.closeStatusChangeModal,
      "confirm": _vm.changeStatus,
      "close": _vm.closeStatusChangeModal
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        return [_c('div', {
          staticClass: "sygni-modal-form"
        }, [_c('div', {
          staticClass: "sygni-modal-form__group"
        }, [_c('sygni-select', {
          staticClass: "secondary",
          attrs: {
            "options": _vm.caseStatusOptions,
            "clear-on-select": false,
            "label": "STATUS"
          },
          model: {
            value: _vm.newCaseStatus,
            callback: function callback($$v) {
              _vm.newCaseStatus = $$v;
            },
            expression: "newCaseStatus"
          }
        })], 1)])];
      },
      proxy: true
    }], null, false, 1126016085)
  }) : _vm._e()], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }