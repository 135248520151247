var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "categories-panel"
  }, [_c('sygni-loader', {
    attrs: {
      "isLoading": _vm.isLoading
    }
  }), _c('div', {
    staticClass: "categories-panel__container"
  }, [_c('sygni-container-title', {
    attrs: {
      "inline": true
    }
  }, [_vm._v("Categories")]), _c('div', {
    staticClass: "categories-panel__group"
  }, [_c('p', {
    staticClass: "categories-panel__label label"
  }, [_vm._v("REQUIRED")]), _vm._l(_vm.requiredCategories, function (category) {
    return _c('div', {
      key: category.category,
      staticClass: "categories-panel__row"
    }, [_c('div', {
      staticClass: "categories-panel__row-item"
    }, [_c('div', {
      class: ['category-box', _vm.isWhistleblowerComplianceOfficer ? 'category-box--clickable' : ''],
      on: {
        "click": function click($event) {
          return _vm.viewCategory(category.category);
        }
      }
    }, [_c('p', {
      staticClass: "category-box__title"
    }, [_vm._v(_vm._s(category.category))]), _c('div', {
      staticClass: "category-box__actions"
    }, [_c('p', {
      staticClass: "category-box__counter"
    }, [_vm._v(_vm._s(category.cases) + " " + _vm._s(category.cases == 1 ? 'CASE' : 'CASES'))]), _vm.isWhistleblowerComplianceOfficer ? _c('img', {
      staticClass: "category-box__arrow",
      attrs: {
        "src": require("@/assets/icons/arrow.svg"),
        "alt": ">"
      }
    }) : _vm._e()])])])]);
  })], 2), _vm.customCategories.length ? _c('div', {
    staticClass: "categories-panel__group"
  }, [_c('p', {
    staticClass: "categories-panel__label label"
  }, [_vm._v("CUSTOM")]), _vm._l(_vm.customCategories, function (category) {
    return _c('div', {
      key: category.category,
      staticClass: "categories-panel__row"
    }, [_c('div', {
      staticClass: "categories-panel__row-item"
    }, [_c('div', {
      class: ['category-box', _vm.isWhistleblowerComplianceOfficer ? 'category-box--clickable' : ''],
      on: {
        "click": function click($event) {
          return _vm.viewCategory(category.category);
        }
      }
    }, [_c('p', {
      staticClass: "category-box__title"
    }, [_vm._v(_vm._s(category.category))]), _c('div', {
      staticClass: "category-box__actions"
    }, [_c('p', {
      staticClass: "category-box__counter"
    }, [_vm._v(_vm._s(category.cases) + " " + _vm._s(category.cases == 1 ? 'CASE' : 'CASES'))]), _vm.isWhistleblowerComplianceOfficer ? _c('img', {
      staticClass: "category-box__arrow",
      attrs: {
        "src": require("@/assets/icons/arrow.svg"),
        "alt": ">"
      }
    }) : _vm._e()])])]), _c('div', {
      staticClass: "categories-panel__row-actions"
    }, [_c('sygni-square-button', {
      staticClass: "outline red",
      on: {
        "click": function click($event) {
          return _vm.deleteCustomCategory(category);
        }
      }
    }, [_c('img', {
      attrs: {
        "src": _vm._f("getIcon")('DELETE'),
        "alt": ""
      }
    })])], 1)]);
  })], 2) : _vm._e(), _c('div', {
    staticClass: "categories-panel__group categories-panel__group--action"
  }, [_c('div', {
    staticClass: "categories-panel__row"
  }, [_c('div', {
    staticClass: "categories-panel__row-item"
  }, [_c('sygni-input', {
    staticClass: "secondary",
    attrs: {
      "label": "ADD NEW CATEGORY"
    },
    on: {
      "submit": function submit($event) {
        return _vm.addNewCustomCategory(_vm.newCategory);
      }
    },
    model: {
      value: _vm.newCategory,
      callback: function callback($$v) {
        _vm.newCategory = $$v;
      },
      expression: "newCategory"
    }
  })], 1), _c('div', {
    staticClass: "categories-panel__row-actions"
  }, [_c('sygni-rounded-button', {
    staticClass: "filled black",
    on: {
      "click": function click($event) {
        return _vm.addNewCustomCategory(_vm.newCategory);
      }
    }
  }, [_vm._v("Add new category")])], 1)])])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }