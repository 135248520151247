




































































import Component from 'vue-class-component'
import ExpandCollapse from "@/components/animations/ExpandCollapse.vue";
import SygniTable from "@/components/table/SygniTable.vue";
import SygniCheckbox from "@/components/inputs/SygniCheckbox.vue";
import UserPresentation from "@/components/UserPresentation.vue";
import SygniRoundedButton from "@/components/buttons/SygniRoundedButton.vue";
import SygniSquareButton from "@/components/buttons/SygniSquareButton.vue";
import SygniRectButton from "@/components/buttons/SygniRectButton.vue";
import SygniArrowButton from "@/components/buttons/SygniArrowButton.vue";
import {BSpinner, BTable, BvTableField} from "bootstrap-vue";
import SygniCircleButton from "@/components/buttons/SygniCircleStatusButton.vue";
import InvestorsInnerTable from "@/modules/genprox/modules/fund/modules/capital-rise/components/InvestorsInnerTable.vue";
import { CheckboxOption } from '@/store/types';
import { CaseItem } from '../store/types';

@Component({
  components: {
    SygniCircleButton,
    InvestorsInnerTable, SygniArrowButton, SygniRoundedButton, UserPresentation, ExpandCollapse, BSpinner, SygniCheckbox, SygniSquareButton, SygniRectButton, BTable },
})
export default class WhistleblowerTable extends SygniTable<any> {

  bulkRows: Array<CheckboxOption> = [];
  selectAllRowsBoolean: boolean | null = false;

  tableFields: (BvTableField & {borderless?: Boolean} & { key: string })[] = [
    { key: 'token', sortable: true, label: 'Case token' },
    { key: 'createdAt', sortable: true, label: 'Created' },
    { key: 'name', sortable: true, class: 'min-width', label: 'Author' },
    { key: 'category', sortable: true, class: 'min-width', label: 'Category'},
    { key: 'title', sortable: true, class: 'full-width', label: 'Title' },
    { key: 'status', sortable: true, label: 'Case Status' },
    { key: 'actions', sortable: false, label: '' },
  ];

  getCaseStatusClassName(status: string) {
    let className = 'danger';
    switch(status) {
      case('new'):
        className = 'danger';
        break;
      case('investigated'):
        className = 'primary';
        break;
      case('awaiting'):
        className = 'success';
        break;
      case('closed'):
        className = 'default';
        break;
      case('spam'):
        className = 'default';
        break;
      default:
        className = 'danger';
    }

    return className;
  }

  setQuery() {
    this.$store.commit('whistleblower/setCasesTableQuery', this.localTableQuery);
  }

  onFiltersChange(filtersQuery?: string): void {
    this.$store.commit(this.setTableBusyMutation, true);
    this.$store.commit('whistleblower/setCasesTableFiltersQuery', filtersQuery);
    this.paginationInstance.$emit('changePage', 1);
    this.$nextTick(() => {
      const sign: string = this.sortDesc ? '-' : '';
      let sortBy: string = '';

      switch(this.sortBy) {
        default:
          sortBy = this.sortBy;
          break;
      }

      this.sortingQuery = {
        name: sortBy,
        order: sign,
      }
      
      this.$store.commit('whistleblower/setCasesTableSortingQuery', this.sortingQuery);
      this.getItems();
    });
  }

  onSortChange(): void {
    this.$store.commit(this.setTableBusyMutation, true);
    this.paginationInstance.$emit('changePage', 1);
    this.$nextTick(() => {
      const sign: string = this.sortDesc ? '-' : '';
      let sortBy: string = '';

      switch(this.sortBy) {
        default:
          sortBy = this.sortBy;
          break;
      }

      this.sortingQuery = {
        name: sortBy,
        order: sign,
      }
      
      this.$store.commit('whistleblower/setCasesTableSortingQuery', this.sortingQuery);
      this.getItems();
    });
  }

  onRowClick(row: CaseItem) {
    this.$emit('rowClick', row)
  }

  async getItems() {
    await this.$store.dispatch('whistleblower/getCases', this.filtersQuery);
  }

  get filtersQuery() {
    return this.$store.getters['whistleblower/getCasesTableFiltersQuery'];
  }

  async beforeMount() {
    await this.getItems();
    this.onMounted();
    this.setBorderlessTds();
  }
}

