var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "create-case-modal"
  }, [_c('div', {
    staticClass: "create-case-modal__bg",
    on: {
      "click": _vm.closeModal
    }
  }), _c('div', {
    staticClass: "create-case-modal__box"
  }, [_c('div', {
    class: ['create-case-modal__loader', _vm.isLoading ? 'active' : '']
  }, [_c('div', {
    staticClass: "spinner"
  }, [_c('b-spinner')], 1)]), _c('div', {
    staticClass: "create-case-modal__actions-bar"
  }, [_c('button', {
    staticClass: "create-case-modal__action create-case-modal__action--close",
    on: {
      "click": _vm.closeModal
    }
  }, [_vm._v("Close")])]), _c('div', {
    staticClass: "create-case-modal__inner"
  }, [_c('div', {
    staticClass: "create-case-modal__body"
  }, [_c('sygni-container-title', [_vm._v("Create new case")]), _c('div', {
    staticClass: "create-case-modal__form"
  }, [_c('sygni-select', {
    staticClass: "secondary",
    attrs: {
      "options": _vm.categories,
      "label": "CATEGORY",
      "validation": _vm.$v.formData.category
    },
    on: {
      "input": function input($event) {
        return _vm.$v.formData.category.$touch();
      }
    },
    model: {
      value: _vm.formData.category,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "category", $$v);
      },
      expression: "formData.category"
    }
  }), _c('sygni-input', {
    staticClass: "secondary",
    attrs: {
      "label": "TITLE",
      "validation": _vm.$v.formData.title
    },
    on: {
      "blur": function blur($event) {
        return _vm.$v.formData.title.$touch();
      }
    },
    model: {
      value: _vm.formData.title,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "title", $$v);
      },
      expression: "formData.title"
    }
  }), _c('sygni-text-area', {
    staticClass: "secondary",
    attrs: {
      "label": "DESCRIPTION"
    },
    model: {
      value: _vm.formData.description,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "description", $$v);
      },
      expression: "formData.description"
    }
  }), _c('div', {
    staticClass: "create-case-modal__files"
  }, [_c('sygni-droparea', {
    staticClass: "secondary",
    attrs: {
      "dropAreaText": "Drag and drop to upload your file",
      "isLoading": _vm.areFilesLoading
    },
    on: {
      "change": function change($event) {
        return _vm.addFiles($event);
      }
    }
  }), _c('div', {
    staticClass: "create-case-modal__files-listing"
  }, _vm._l(_vm.uploadedFiles, function (file) {
    return _c('sygni-file-box', {
      key: file.id,
      attrs: {
        "icon": "DELETE"
      },
      on: {
        "click": function click($event) {
          return _vm.deleteFile(file);
        }
      }
    }, [_vm._v(_vm._s(file.fileName))]);
  }), 1)], 1), _c('div', {
    staticClass: "create-case-modal__details"
  }, [_c('sygni-input', {
    staticClass: "secondary",
    attrs: {
      "label": "NAME <strong>(OPTIONAL)</strong>"
    },
    model: {
      value: _vm.formData.name,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "name", $$v);
      },
      expression: "formData.name"
    }
  }), _c('sygni-input', {
    staticClass: "secondary",
    attrs: {
      "label": "SURNAME <strong>(OPTIONAL)</strong>"
    },
    model: {
      value: _vm.formData.surname,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "surname", $$v);
      },
      expression: "formData.surname"
    }
  }), _c('sygni-input', {
    staticClass: "secondary",
    attrs: {
      "label": "E-MAIL <strong>(OPTIONAL)</strong>"
    },
    model: {
      value: _vm.formData.email,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "email", $$v);
      },
      expression: "formData.email"
    }
  }), _c('sygni-input', {
    staticClass: "secondary",
    attrs: {
      "label": "PHONE <strong>(OPTIONAL)</strong>"
    },
    model: {
      value: _vm.formData.phone,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "phone", $$v);
      },
      expression: "formData.phone"
    }
  })], 1)], 1), _c('div', {
    staticClass: "create-case-modal__actions"
  }, [_c('sygni-rounded-button', {
    staticClass: "filled red",
    on: {
      "click": _vm.createNewCase
    }
  }, [_vm._v("Send")])], 1)], 1)])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }